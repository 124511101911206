<template>
  <div class="login flex">
    <div class="topRight common">
      <img src="../../assets/img/animalTop.png" alt="">
    </div>
    <div class="bottomLeft common">
      <img src="../../assets/img/animalBottom.png" alt="">
    </div>
    <div class="loginContent">
      <div class="content">
        <div class="logo"><img src="../../assets/img/ysLogo.png" alt=""></div>
        <div class="start flex">
          <div class="left">
<!--            <img src="../../assets/img/logo.png" alt="" class="logoT">-->
            <h1>{{ $store.state.setting.systemName }}</h1>
            <h4>{{ $store.state.setting.enName }}</h4>
            <el-form ref="formInline" :model="formInline" class="formInline" :rules="rules">
              <template v-if="show">
                <el-form-item prop="companyCode">
                  <el-input prefix-icon="el-icon-key" placeholder="请输入企业代码"
                            v-model="formInline.companyCode" clearable></el-input>
                </el-form-item>
                <el-form-item prop="preUsername">
                  <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入用户名"
                            v-model="formInline.preUsername" clearable></el-input>
                </el-form-item>
                <el-form-item prop="mobile">
                  <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号码"
                            v-model="formInline.mobile" clearable></el-input>
                </el-form-item>
                <el-form-item class="imageCode" prop="smsCode">
                  <el-input placeholder="请输入验证码" prefix-icon="el-icon-unlock"
                            v-model="formInline.smsCode"></el-input>
                  <el-link class="text" :underline="false" @click="send">
                    <template v-if="showCode"> 获取验证码</template>
                    <template v-else>{{ count }} s</template>
                  </el-link>
                </el-form-item>
                <el-form-item class="btnGroup">
                  <el-button type="primary" size="small" @click="nextForm">下一步</el-button>
                  <el-link :underline="false" href="login">返回登录</el-link>
                </el-form-item>
              </template>
              <template v-if="!show">
                <el-form-item prop="password">
                  <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" type="password"
                            v-model="formInline.password" clearable></el-input>
                </el-form-item>
                <el-form-item prop="dpwd">
                  <el-input placeholder="请输入新密码" prefix-icon="el-icon-lock" type="password"
                            v-model="formInline.dpwd" clearable></el-input>
                </el-form-item>
                <el-form-item class="btnGroup">
                  <el-button type="primary" size="small" @click="handleSubmit('formInline')" :disabled="isDisable">确认修改
                  </el-button>
                  <el-link :underline="false" @click="show = true">上一步</el-link>
                  <el-link :underline="false" href="login">返回登录</el-link>
                </el-form-item>
              </template>
            </el-form>
            <div class="littleFeet">
              <img src="../../assets/img/feetS.png" alt="" class="feetImgS ">
              <img src="../../assets/img/feetL.png" alt="" class="feetImgL">
            </div>
          </div>
          <div class="right">
            <img src="../../assets/img/animalSys.png" alt="" width="100%">
            <img src="../../assets/img/mouse.png" alt="" width="90%" class="mouse">
          </div>
        </div>
      </div>
      <div class="footer" v-html=" $store.state.setting.copyright"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "forget",
  data() {
    return {
      show: true,
      isDisable: false,
      formInline: {
        companyCode: '',
        preUsername: '',
        password: '',
        dpwd: '',
        smsCode: '',
        mobile: ''
      },
      rules: {
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {type: 'string', min: 3, message: '密码长度不能少于三位', trigger: 'blur'}
        ]
      },
      showCode: true,  // 初始启用按钮
      count: '',   // 初始化次数
      timer: null,
    }
  },
  mounted() {
  },
  methods: {
    // 发送验证码
    send() {
      const TIME_COUNT = 60; //更改倒计时时间
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);  // 清除定时器
            this.timer = null;
          }
        }, 1000)
      }
      this.$get('/code/sms', {
        companyCode: this.formInline.companyCode,
        preUsername: this.formInline.preUsername,
        mobile: this.formInline.mobile,
      }).then(() => {
        this.$message.success("发送验证码成功");
      })
    },
    nextForm() {
      if ($.trim(this.formInline.preUsername).length === 0) {
        this.$message.warning("用户名不能为空");
        return;
      }
      if ($.trim(this.formInline.smsCode).length === 0) {
        this.$message.warning("请填写验证码");
        return;
      }
      this.show = false
    },
    // 提交
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.isDisable = true
          this.$put('password', this.formInline).then(() => {
            this.$message.success("修改成功");
            setTimeout(this.$router.go(-1), 1200)
          })
        } else {
          this.$message.error("输入有误，请重新输入");
        }
      })

    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/login";
</style>
